import React from 'react';
import { CSVLink as RawLink } from 'react-csv';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { values } from 'ramda';
import { styled } from '@mui/material/styles';

const CSVLink = styled(RawLink)(() => ({
  color: 'primary',
  textDecoration: 'none',
}));

const ButtonCsvDownload = ({
  rowData,
  emails = (data) => data === data, // eslint-disable-line
  buttonName = 'Download CSV with emails',
}) => {
  const filterEmails = values(rowData.registered)
    .filter(({ gdprConfirm }) => gdprConfirm)
    .map(({ email }) => ({ email }))
    .filter(emails);
  return (
    <Button variant="outlined" size="small" sx={{ py: 2, my: 1 }} startIcon={<SaveIcon />}>
      <CSVLink
        target="_blank"
        data={filterEmails}
        filename={`Emails_from_${rowData.name}.csv`}
        headers={[{ label: 'Email', key: 'email' }]}
      >
        {buttonName}
      </CSVLink>
    </Button>
  );
};
export default ButtonCsvDownload;
