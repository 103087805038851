import React from 'react';
import Typography from '@mui/material/Typography';
import { values } from 'ramda';
import { isStringTrue, isStringFalse } from '../../utils/truthChecks';

const NumOfRegisteredUsers = ({ rowData }) => {
  const usersNoWaitList = values(rowData.registered).filter(({ waitingList }) =>
    isStringFalse(waitingList)
  );
  const noUsersLimit = (
    <Typography sx={{ p: 1 }}>
      <span>Number of registered users:</span>
      <b>{rowData.registered ? ` ${Object.keys(rowData.registered).length}` : ''}</b>
    </Typography>
  );
  const limitedNumberOfUsers = (
    <>
      <Typography sx={{ p: 1 }}>
        <span>Number of registered users: </span>
        <b>{` ${Object.keys(usersNoWaitList).length}`}</b>
      </Typography>
      <Typography sx={{ p: 1 }}>
        <span>Maximum number of users: </span>
        <b>{rowData.maxUsers}</b>
      </Typography>
      <Typography sx={{ p: 1 }}>
        <span>Number of users on waiting list: </span>
        <b>
          {values(rowData.registered).filter(({ waitingList }) => isStringTrue(waitingList)).length}
        </b>
      </Typography>
    </>
  );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{rowData.maxUsers ? limitedNumberOfUsers : noUsersLimit}</>;
};
export default NumOfRegisteredUsers;
