import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import { useLogin } from '../AppState';

function SignIn() {
  const login = useLogin();

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
      <CssBaseline />
      <Stack flexDirection="column" alignItems="center">
        <Avatar sx={{ m: 1, backgroundColor: 'secondary' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values, { setSubmitting }) => {
            login(values, setSubmitting).catch(() => {
              // eslint-disable-next-line
              alert('Wrong password or username');
            });
          }}
        >
          {({ values, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Sign in
                </Button>
              </form>
            </Box>
          )}
        </Formik>
      </Stack>
    </Container>
  );
}

export default SignIn;
