import React from 'react';
import MoneyOff from '@mui/icons-material/MoneyOffTwoTone';
import dayjs from 'dayjs';
import CRUDTable from '../CRUDTable';
import ListOfUsers from '../ListOfUsers';
import { isStringFalse } from '../../../utils/truthChecks';

const RegisteredUsers = () => (
  <CRUDTable
    dataManipulation={(data) => data.filter((record) => dayjs(record.date) >= dayjs(new Date()))}
    database="workshops"
    dataDescriptor={() => [
      { title: 'Name', field: 'name' },
      {
        title: 'Date',
        field: 'date',
        render: (rowData) => (rowData.date ? dayjs(rowData.date).format('DD.MM.YYYY') : ''),
      },
    ]}
    key="registeredUsers"
    detailPanel={({ headers, fetchNewData }) =>
      ({ rowData }) =>
        (
          <ListOfUsers
            rowData={rowData}
            headers={headers}
            fetchNewData={fetchNewData}
            filter={(value) => isStringFalse(value.paid)}
          />
        )}
  />
);

export default {
  id: 'registeredUsers',
  name: 'RegisteredUsers',
  icon: <MoneyOff />,
  Content: RegisteredUsers,
};
