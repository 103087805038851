import React, { useEffect, useState, memo } from 'react';
import MaterialTable from '@material-table/core';
import Add from '@mui/icons-material/Add';
import Clear from '@mui/icons-material/Clear';
import Check from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Export from '@mui/icons-material/SaveAlt';
import Filter from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import NextPage from '@mui/icons-material/ChevronRight';
import PreviousPage from '@mui/icons-material/ChevronLeft';
import Search from '@mui/icons-material/Search';
import SortArrow from '@mui/icons-material/ArrowUpward';
import ThirdStateCheck from '@mui/icons-material/Remove';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { postData, getData, putData, deleteData } from '../../utils/fetch';
import { useAppState } from '../AppState';

function SimpleTable({
  database,
  dataDescriptor,
  detailPanel,
  dataManipulation = (data) => data,
  urlPath = (newData) => newData.key,
  components,
  hasRowAdding = true,
}) {
  const [tableData, setTableData] = useState([]);
  const [fetchData, setFetchData] = useState(0);
  const [fetching, setFetching] = useState(true);
  const { state } = useAppState();
  const headers = {
    Authorization: `Bearer ${state.user.idToken}`,
    'Content-Type': 'application/json',
  };
  const urlBase = `${process.env.FIREBASE_API_URL}/${database}`;
  const fetchNewData = () => {
    setFetchData(fetchData + 1);
  };

  // for some reason material table is fucked up so i need to fetch data manually
  useEffect(() => {
    setFetching(true);

    getData(urlBase, headers).then((response) => {
      setTableData(dataManipulation(response));
      setFetching(false);
    });
  }, [fetchData]);

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        isLoading={fetching}
        columns={dataDescriptor(tableData)}
        icons={{
          Add,
          Check,
          Clear,
          Delete,
          DetailPanel: NextPage,
          Edit,
          Export,
          Filter,
          FirstPage,
          LastPage,
          NextPage,
          PreviousPage,
          ResetSearch: Clear,
          Search,
          SortArrow,
          ThirdStateCheck,
          ViewColumn,
        }}
        data={tableData}
        title=""
        editable={{
          onRowAdd: hasRowAdding
            ? (newData) => postData(urlBase, newData, headers).then(fetchNewData)
            : undefined,
          onRowUpdate: (newData) =>
            putData(`${urlBase}/${urlPath(newData)}`, newData, headers).then(fetchNewData),
          onRowDelete: (oldData) =>
            deleteData(`${urlBase}/${urlPath(oldData)}`, headers).then(fetchNewData),
        }}
        detailPanel={detailPanel && detailPanel({ headers, fetchNewData })}
        options={{
          pageSize: 20,
          actionsColumnIndex: -1,
          filtering: true,
          sorting: true,
        }}
        components={components}
      />
    </div>
  );
}

export default memo(SimpleTable);
