import React, { useState, useRef, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const OverflowTip = (props) => {
  const [overflowed, setOverflowed] = useState(false);
  const textElement = useRef();
  useEffect(() => {
    setOverflowed(textElement.current.scrollWidth > textElement.current.clientWidth);
  }, []);
  return (
    <Tooltip
      title={<Typography color="inherit">{props.children}</Typography>}
      disableHoverListener={!overflowed}
    >
      <div
        ref={textElement}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 100,
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
