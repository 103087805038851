import React from 'react';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import OverflowTip from '../OverflowTip';
import CRUDTable from '../CRUDTable';
import NumOfRegisteredUsers from '../NumOfRegisteredUsers';
import ButtonCsvDownload from '../ButtonCsvDownload';
import ListOfUsers from '../ListOfUsers';

const Webinars = () => (
  <CRUDTable
    database="webinars"
    dataDescriptor={() => [
      { title: 'Name', field: 'name' },
      { title: 'Event link', field: 'eventLink' },
      { title: 'Place', field: 'place' },
      {
        title: 'Place Link',
        field: 'placeLink',
        render: (rowData) => <OverflowTip>{rowData.placeLink}</OverflowTip>,
      },
      { title: 'Price', field: 'price' },
      {
        title: 'Date',
        field: 'date',
        type: 'date',
        render: (rowData) => (rowData.date ? dayjs(rowData.date).format('DD.MM.YYYY') : ''),
      },
      {
        title: 'Date free text',
        field: 'dateFreeText',
      },
      { title: 'Friendly URL', field: 'friendlyUrl' },
    ]}
    key="webinars"
    detailPanel={({ headers, fetchNewData }) =>
      ({ rowData }) =>
        (
          <>
            <NumOfRegisteredUsers rowData={rowData} />
            <ButtonCsvDownload rowData={rowData} />
            <ListOfUsers
              rowData={rowData}
              headers={headers}
              fetchNewData={fetchNewData}
              type="webinar"
            />
          </>
        )}
  />
);

export default { id: 'webinars', name: 'Webinars', icon: <EventIcon />, Content: Webinars };
