import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import CRUDTable from '../CRUDTable';

const Jobs = () => (
  <CRUDTable
    database="job"
    dataDescriptor={() => [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
    ]}
    key="jobs"
  />
);

export default {
  id: 'jobs',
  name: 'Jobs',
  icon: <WorkIcon />,
  Content: Jobs,
};
