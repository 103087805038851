import React from 'react';
import dayjs from 'dayjs';
import { map, prop, compose, uniq } from 'ramda';
import { valueMirror, rejectNil } from 'ramda-extension';
import AllInboxIcon from '@mui/icons-material/AllInbox';

import CRUDTable from '../CRUDTable';

const Gdpr = () => (
  <CRUDTable
    dataManipulation={(data) =>
      data.sort((a, b) => (dayjs(a.confirmDate).isBefore(dayjs(b.confirmDate)) ? 1 : -1))
    }
    database="gdpr"
    dataDescriptor={(tableData) => [
      { title: 'Confirmed', field: 'confirmed', type: 'boolean' },
      {
        title: 'Confirmed date',
        field: 'confirmDate',
        type: 'date',
        render: (rowData) =>
          rowData.confirmDate ? dayjs(rowData.confirmDate).format('DD.MM.YYYY HH:mm') : '',
      },
      { title: 'Name', field: 'name' },
      { title: 'E-mail', field: 'email' },
      { title: 'Address', field: 'address' },
      { title: 'Tender', field: 'tender' },
      { title: 'Birth date', field: 'birthDate' },
      {
        title: 'Event',
        field: 'event',
        lookup: compose(valueMirror, uniq, rejectNil, map(prop('event')))(tableData),
      },
    ]}
    key="gdpr"
  />
);

export default {
  id: 'gdpr',
  name: 'Gdpr',
  icon: <AllInboxIcon />,
  Content: Gdpr,
};
