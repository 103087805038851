import React from 'react';
import { navigate } from 'gatsby';
import { keyMirror } from 'ramda-extension';
import { postData } from '../utils/fetch';

const AppState = React.createContext();

const actions = keyMirror({
  AUTH: null,
});

const initState = { auth: false };

const reducer = (state, action) => {
  switch (action.type) {
    case actions.AUTH: {
      return { user: action.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
};

export const useAppState = () => {
  const [state] = React.useContext(AppState);
  return {
    state,
  };
};

export const useLogin = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, dispatch] = React.useContext(AppState);
  return async (values, setSubmitting) => {
    const response = await postData(process.env.FIREBASE_AUTH_URL, {
      ...values,
      returnSecureToken: true,
    });
    setSubmitting(false);
    if (response.error) {
      return Promise.reject();
    }
    dispatch({ type: actions.AUTH, payload: response });
    navigate('/admin/dashboard');
    return undefined;
  };
};

export const AppStateProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initState);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <AppState.Provider value={value} {...props} />;
};
