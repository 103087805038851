import React from 'react';
import { navigate } from 'gatsby';
import { isEmpty } from 'ramda';
import { useAppState } from '../AppState';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { state } = useAppState();
  if (!state.user || (isEmpty(state.user) && location.pathname !== `/admin/login`)) {
    navigate(`/admin/login`);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
