import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { values } from 'ramda';
import { putData } from '../../utils/fetch';

const listOfUsers = ({
  rowData,
  filter = (data) => data === data, // eslint-disable-line
  subheader = 'Paid',
  headers,
  fetchNewData,
  type = 'workshop',
}) => (
  <List subheader={<ListSubheader>{subheader}</ListSubheader>} dense>
    {values(rowData.registered)
      .filter(filter)
      .map((value) => (
        <ListItem
          key={value.email}
          dense
          onClick={() => {
            putData(
              `${process.env.FIREBASE_API_URL}/private/${type}/paid/${rowData.key}/${value.email}/${
                value.paid !== 'true'
              }`,
              {},
              headers
            ).then(fetchNewData);
          }}
        >
          <ListItemIcon>
            <Checkbox edge="start" checked={value.paid === 'true'} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText
            primary={`${value.name} - ${value.email} - Date of registration: ${value.dateOfRegistration} - Discount: ${value.discount}`}
            secondary={`${value.phone}, ${value.company},${value.personId}, ${value.street}, ${value.city}, ${value.postalCode}`}
          />
        </ListItem>
      ))}
  </List>
);

export default listOfUsers;
