import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { mergeDeepRight } from 'ramda';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import { MTableToolbar } from '@material-table/core';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import CRUDTable from '../CRUDTable';
import OverflowTip from '../OverflowTip';
import topicHint from '../../../../assets/images/topicDetail.jpg';
import { getData } from '../../../utils/fetch';
import { useAppState } from '../../AppState';
import { WORKSHOP_STATUS } from '../../../utils/constants';

const Topics = () => {
  const [hintOpen, setHintOpen] = useState(false);
  const [workshops, setWorkshops] = useState([]);
  const { state } = useAppState();

  const headers = {
    Authorization: `Bearer ${state.user.idToken}`,
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    getData(`${process.env.FIREBASE_API_URL}/workshops`, headers).then((response) => {
      setWorkshops(response);
    });
  }, []);

  const greenText = {
    color: 'green',
  };
  const redText = {
    color: 'red',
  };

  const specifiedTextField = (label, props) => (
    <TextField
      id="outlined-multiline-static"
      label={label}
      multiline
      rows={4}
      variant="outlined"
      helperText="Press ENTER for new line"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      style={{ minWidth: '180px' }}
    />
  );

  const descriptor = useMemo(
    () => () =>
      [
        { title: 'Order', field: 'topicOrder', type: 'numeric' },
        {
          title: 'Topic Logo',
          field: 'topicLogo',
          render: (rowData) => (
            <img src={rowData.topicLogo} alt="topic Logo" style={{ width: 100 }} />
          ),
        },
        { title: 'Topic name', field: 'topicName' },
        {
          title: 'Topic description',
          field: 'topicDescription',
          render: (rowData) => <OverflowTip>{rowData.topicDescription}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Topic Description', props),
        },
        {
          title: 'Motto',
          field: 'topicMotto',
        },
        {
          title: 'Speaker Photo',
          field: 'topicSpeakerPhoto',
          render: (rowData) => (
            <img src={rowData.topicSpeakerPhoto} alt="Speaker" style={{ width: 100 }} />
          ),
        },
        {
          title: 'Topic URL',
          field: 'topicUrl',
        },
        {
          title: 'Short Description',
          field: 'topicShortDescription',
          render: (rowData) => <OverflowTip>{rowData.topicShortDescription}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Short Description', props),
        },
        {
          title: 'Schedule Information',
          field: 'topicScheduleInfo',
          render: (rowData) => <OverflowTip>{rowData.topicScheduleInfo}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Schedule Information', props),
        },
        {
          title: 'Speaker biography',
          field: 'topicSpeakerBio',
          render: (rowData) => <OverflowTip>{rowData.topicSpeakerBio}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Speaker biography', props),
        },
        {
          title: 'Speaker name',
          field: 'topicSpeakerName',
        },
        {
          title: 'Speaker FB link',
          field: 'topicSpeakerFB',
          render: (rowData) => <OverflowTip>{rowData.topicSpeakerFB}</OverflowTip>,
        },
        {
          title: 'Speaker LinkedIn',
          field: 'topicSpeakerLinkedIn',
          render: (rowData) => <OverflowTip>{rowData.topicSpeakerLinkedIn}</OverflowTip>,
        },
        {
          title: 'Reference 1',
          field: 'topicReference1',
          render: (rowData) => <OverflowTip>{rowData.topicReference1}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Reference 1', props),
        },
        {
          title: 'Reference 2',
          field: 'topicReference2',
          render: (rowData) => <OverflowTip>{rowData.topicReference2}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Reference 2', props),
        },
        {
          title: 'Reference 3',
          field: 'topicReference3',
          render: (rowData) => <OverflowTip>{rowData.topicReference3}</OverflowTip>,
          editComponent: (props) => specifiedTextField('Reference 3', props),
        },
      ],
    []
  );

  const detailPanel = useMemo(
    () =>
      () =>
      ({ rowData }) =>
        (
          <div style={{ padding: '0 20px 20px 20px', maxWidth: '600px' }}>
            <p>Schedule</p>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <b>Status</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Date</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Price</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Place</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workshops
                    .filter((workshop) => workshop.name === rowData.topicName)
                    .map((workshop) => (
                      <TableRow key={workshop.key}>
                        <TableCell
                          style={workshop.status === WORKSHOP_STATUS.active ? greenText : redText}
                          align="left"
                        >
                          {workshop.status}
                        </TableCell>
                        <TableCell align="left">
                          {dayjs(workshop.date).format('DD.MM.YYYY')}
                        </TableCell>
                        <TableCell align="left">{workshop.price}</TableCell>
                        <TableCell align="left">{workshop.place}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ),
    [workshops]
  );

  const components = useMemo(
    () => ({
      Toolbar: (props) => (
        <div>
          <MTableToolbar {...props} />
          <button
            style={{
              width: '190px',
              height: '30px',
              backgroundColor: 'lightBlue',
            }}
            type="button"
            onClick={() => setHintOpen(true)}
          >
            Click for hint
          </button>
        </div>
      ),
    }),
    []
  );

  const dataManipulation = useCallback(
    (data) => data.map((rowData) => mergeDeepRight(rowData, { actualWorkshops: workshops })),
    [workshops]
  );

  return (
    <>
      <Dialog open={hintOpen} onClose={() => setHintOpen(false)}>
        <img src={topicHint} alt="for companies" height="860" width="640" />
      </Dialog>
      <CRUDTable
        database="topics"
        dataManipulation={dataManipulation}
        dataDescriptor={descriptor}
        key="topics"
        detailPanel={detailPanel}
        components={components}
      />
    </>
  );
};

export default { id: 'topics', name: 'Topics', icon: <EventIcon />, Content: Topics };
