import React, { useEffect, useState } from 'react';
import { valueMirror } from 'ramda-extension';
import EventIcon from '@mui/icons-material/Event';
import { Typography } from '@mui/material';
import OverflowTip from '../OverflowTip';
import CRUDTable from '../CRUDTable';
import NumOfRegisteredUsers from '../NumOfRegisteredUsers';
import ButtonCsvDownload from '../ButtonCsvDownload';
import { getData } from '../../../utils/fetch';
import { useAppState } from '../../AppState';
import ListOfUsers from '../ListOfUsers';
import { isStringFalse, isStringTrue } from '../../../utils/truthChecks';
import { WORKSHOP_STATUS } from '../../../utils/constants';

const Trainings = () => {
  const [topics, setTopics] = useState([]);
  const { state } = useAppState();

  const mainHeaders = {
    Authorization: `Bearer ${state.user.idToken}`,
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    getData(`${process.env.FIREBASE_API_URL}/topics`, mainHeaders).then((response) => {
      setTopics(response);
    });
  }, []);

  const dynamicLookupObjectOfName = topics.map((topic) => topic.topicName);
  const dynamicLookupObjectOfDescription = topics.map((topic) => topic.topicDescription);
  const dynamicLookupObjectOfTopicUrl = topics.map((topic) => topic.topicUrl);

  const isWaitingListFalse = ({ waitingList }) => isStringFalse(waitingList);
  const isWaitingListTrue = ({ waitingList }) => isStringTrue(waitingList);

  const greenText = {
    color: 'green',
  };
  const redText = {
    color: 'red',
  };

  return (
    <CRUDTable
      database="trainings"
      dataDescriptor={() => [
        {
          title: 'Status',
          field: 'status',
          render: (rowData) => (
            <Typography style={rowData.status === WORKSHOP_STATUS.active ? greenText : redText}>
              {rowData.status?.toUpperCase()}
            </Typography>
          ),
          lookup: valueMirror([WORKSHOP_STATUS.active, WORKSHOP_STATUS.canceled]),
        },
        {
          title: 'Name',
          field: 'name',
          lookup: valueMirror(dynamicLookupObjectOfName),
        },
        { title: 'Event link', field: 'eventLink' },
        {
          title: 'Description',
          field: 'description',
          render: (rowData) => <OverflowTip>{rowData.description}</OverflowTip>,
          lookup: valueMirror(dynamicLookupObjectOfDescription),
        },
        {
          title: 'Topic Url',
          field: 'topicUrl',
          lookup: valueMirror(dynamicLookupObjectOfTopicUrl),
        },
        { title: 'Price', field: 'price' },
        { title: 'Friendly URL', field: 'friendlyUrl' },
      ]}
      // 3) "čas od" bude použitý v emailu
      // 4) "čas od" a "čas do" prosím přidat do registračního formuláře
      key="trainings"
      detailPanel={({ headers, fetchNewData }) =>
        ({ rowData }) =>
          (
            <>
              <NumOfRegisteredUsers rowData={rowData} />
              <ButtonCsvDownload rowData={rowData} />
              {rowData.maxUsers && (
                <>
                  <ButtonCsvDownload
                    rowData={rowData}
                    buttonName="Download CSV with emails - Only Registered"
                    emails={isWaitingListFalse}
                  />
                  <ButtonCsvDownload
                    rowData={rowData}
                    buttonName="Download CSV with emails - Waiting List"
                    emails={isWaitingListTrue}
                  />
                </>
              )}
              <ListOfUsers
                rowData={rowData}
                headers={headers}
                fetchNewData={fetchNewData}
                filter={isWaitingListFalse}
              />
              {rowData.maxUsers && (
                <ListOfUsers
                  rowData={rowData}
                  headers={headers}
                  fetchNewData={fetchNewData}
                  subheader="Waiting List"
                  filter={isWaitingListTrue}
                />
              )}
            </>
          )}
    />
  );
};

export default { id: 'trainings', name: 'Trainings', icon: <EventIcon />, Content: Trainings };
