import React from 'react';
import { Router } from '@reach/router';
import Login from '../../admin/components/Login';
import Dashboard from '../../admin/components/Dashboard';
import PrivateRoute from '../../admin/components/PrivateRoute';
import { AppStateProvider } from '../../admin/AppState';

const Admin = () => (
  <AppStateProvider>
    <Router>
      <PrivateRoute path="/admin/dashboard/:section" component={Dashboard} />
      <PrivateRoute path="/admin/dashboard" component={Dashboard} />
      <Login path="/admin/login" />
      <Login path="/admin" />
    </Router>
  </AppStateProvider>
);
export default Admin;
