import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { valueMirror } from 'ramda-extension';
import CRUDTable from '../CRUDTable';

const Users = () => (
  <CRUDTable
    database="user"
    hasRowAdding={false}
    urlPath={(newData) => newData.uid}
    dataDescriptor={() => [
      {
        title: 'Photo url',
        field: 'photoURL',
        render: (rowData) => <img src={rowData.userPhoto} alt="user" style={{ width: 100 }} />,
      },
      { title: 'Name', field: 'displayName' },
      { title: 'Email', field: 'email' },
      { title: 'Email verified', field: 'emailVerified', lookup: valueMirror(['true', 'false']) },
      { title: 'Phone number', field: 'phoneNumber' },
      { title: 'Disabled', field: 'disabled', lookup: valueMirror(['true', 'false']) },
      { title: 'Last sign in time', field: 'metadata.lastSignInTime', editable: 'never' },
      { title: 'Date of registration', field: 'metadata.creationTime', editable: 'never' },
      { title: 'ID (uid)', field: 'uid', editable: 'never' },
    ]}
    key="users"
  />
);

export default { id: 'users', name: 'Users', icon: <GroupIcon />, Content: Users };
